<template>
  <div class="login-container">
    <el-form :model="form" :rules="rules" ref="loginForm" label-width="80px" class="login-form">
      <el-form-item label="Username" prop="username">
        <el-input v-model="form.username" placeholder="Enter your username"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="form.password" placeholder="Enter your password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt';
import axios from '@/config';
import { Message } from 'element-ui';

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: 'Please enter your username', trigger: 'blur' }],
        password: [{ required: true, message: 'Please enter your password', trigger: 'blur' }]
      }
    };
  },
   mounted() {
    // 检查是否已经登录，如果已经登录则重定向到主页
    if (localStorage.getItem('jwtToken')) {
      // console.log('登录了呀')
      // localStorage.setItem('redirect', '/home');
      this.$router.push('/home');
      // window.location.reload();
    } else {
      // console.log('拿pubkey')
      // 如果未登录，则获取后端提供的公钥
      this.getPublicKey();
    }
  },
  methods: {
     async getPublicKey() {
      try {
        // 调用后端接口获取公钥
        const response = await axios.get(`/get_public_key`);
        const publicKey = response.data.data;
        // 将公钥保存到本地
        localStorage.setItem('publicKey', publicKey);
      } catch (error) {
        console.error('Error getting public key:', error);
      }
    },
    async login() {
      try {
        this.$refs.loginForm.validate(async valid => {
          if (valid) {
            // 获取公钥
            const publicKey = localStorage.getItem('publicKey');
            // 使用公钥加密密码
            const encryptedPassword = this.encryptPassword(publicKey, this.form.password);
            // 调用后端登录接口
            // console.log(this.form.password, '000')
            // console.log(encryptedPassword,'111')
            const response = await axios.post(`/login`, {
              username: this.form.username,
              password: encryptedPassword
            });
            const data = response.data;
            if (data.code === '0000') {
              // 登录成功，保存 JWT Token 到本地
              const jwtToken = data.data;
              localStorage.setItem('jwtToken', jwtToken);
              let strings = jwtToken.split(".");
              var userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
              localStorage.setItem('userName', userinfo['sub']['user_name']);
              console.log('userName', localStorage.getItem('userName'))
              // 重定向到主页
              this.$router.push('/home');
            } else {
              // 登录失败，显示错误信息
              console.error('Login failed:', data.msg);
              // 清空输入的密码
              this.form.password = '';
              // 提示用户登录失败
              Message.error(data.msg);
            }
          } else {
            return false;
          }
        });
      } catch (error) {
        console.error('Error logging in:', error);
      }
    },
    encryptPassword(publicKey, password) {
      // console.log(publicKey, password,'3333')
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      return encryptor.encrypt(password);
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  width: 400px;
}
</style>
